<template>
    <div>
        <el-scrollbar class="set-container">
            <div class="main-content">
                <div class="content">
                    <el-tabs v-model="value" @tab-click="handleClick" type="card">
                        <el-tab-pane label="积分设定" name="0"></el-tab-pane>
                        <el-tab-pane label="积分明细" name="1"></el-tab-pane>
                    </el-tabs>
                    <div class="form-content" v-if="value == 0">
                        <div class="form-item">
                            <div class="item-title">店铺签到</div>
                            <div class="item-right">
                                <el-input-number class="number-input" v-model="addForm.sign" ref="sign"
                                                 :precision="0"
                                                 :controls="false"
                                                 :min="1" :max="999999999"></el-input-number>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="item-title">查看寄件物流</div>
                            <div class="item-right">
                                <el-input-number class="number-input" v-model="addForm.express" ref="express"
                                                 :precision="0"
                                                 :controls="false"
                                                 :min="1" :max="999999999"></el-input-number>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="item-title">逛一逛店铺</div>
                            <div class="item-right">
                                <el-input-number class="number-input" v-model="addForm.shopping" ref="shopping"
                                                 :precision="0"
                                                 :controls="false"
                                                 :min="1" :max="999999999"></el-input-number>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="item-title">寄件成功</div>
                            <div class="item-right">
                                <el-input-number class="number-input" v-model="addForm.sending" ref="sending"
                                                 :precision="0"
                                                 :controls="false"
                                                 :min="1" :max="999999999"></el-input-number>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="item-title">取件成功</div>
                            <div class="item-right">
                                <el-input-number class="number-input" v-model="addForm.pick" ref="pick"
                                                 :precision="0"
                                                 :controls="false"
                                                 :min="1" :max="999999999"></el-input-number>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="item-title">查看店铺动态</div>
                            <div class="item-right">
                                <el-input-number class="number-input" v-model="addForm.dynamic" ref="dynamic"
                                                 :precision="0"
                                                 :controls="false"
                                                 :min="1" :max="999999999"></el-input-number>
                            </div>
                        </div>
                        <div class="form-item">
                            <el-button @click="submitRules" type="primary">立刻设定</el-button>
                        </div>
                    </div>
                    <div class="form-content" v-else>
                        <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px"
                                  height="1%">
                            <el-table-column prop="nickname" label="会员名" align="center">
                                <template slot-scope="scope">
                                    <span>{{scope.row.nickname?scope.row.nickname:'未知'}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="mobile" label="电话" align="center">
                                <template slot-scope="scope">
                                    <span>{{scope.row.mobile?scope.row.mobile:'暂无数据'}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="gender" label="性别" align="center">
                                <template slot-scope="scope">
                                    <span>{{scope.row.gender ==0?'未知':scope.row.gender ==1?'男':'女'}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="city" label="城市" align="center">
                                <template slot-scope="scope">
                                    <span>{{scope.row.city?scope.row.city:'暂无数据'}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="later_points" label="积分" align="center"></el-table-column>
                            <el-table-column prop="before_points" label="积分变动" align="center">
                                <template slot-scope="scope">
                                    <span>{{scope.row.later_points - scope.row.before_points}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="type" label="积分类型" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.type == 'sign'">店铺签到</span>
                                    <span v-if="scope.row.type == 'express'">查看物流</span>
                                    <span v-if="scope.row.type == 'shopping'">逛一逛店铺</span>
                                    <span v-if="scope.row.type == 'sending'">寄件成功</span>
                                    <span v-if="scope.row.type == 'pick'">取件成功</span>
                                    <span v-if="scope.row.type == 'dynamic'">查看动态文章</span>
                                    <span v-if="scope.row.type == 'exchange'">兑换商品</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="created_at" label="创建时间" align="center"></el-table-column>
                        </el-table>
                        <el-pagination class="pages-center"
                                       :current-page="listPages.currentPageNum"
                                       :page-size="listPages.eachPageNum"
                                       layout="prev, pager, next, jumper"
                                       :total="listPages.total"
                                       @current-change="pageCurrentChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {points_config_getPointsConfit, points_config_setPointsConfit, points_pointsRecordList} from '@/config/apis'

    export default {
        data() {
            return {
                value: 0,
                addForm: {
                    sign: undefined,
                    express: undefined,
                    shopping: undefined,
                    sending: undefined,
                    pick: undefined,
                    dynamic: undefined,
                },
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getInfo()
            this.getList()
        },
        methods: {
            getInfo() {
                points_config_getPointsConfit().then(res => {
                    this.addForm.sign = res.data.sign
                    this.addForm.express = res.data.express
                    this.addForm.shopping = res.data.shopping
                    this.addForm.sending = res.data.sending
                    this.addForm.pick = res.data.pick
                    this.addForm.dynamic = res.data.dynamic
                })
            },
            getList() {
                let params = {
                    paging: '1',
                    pageSize: this.listPages.eachPageNum,
                    page: this.listPages.currentPageNum,
                }
                points_pointsRecordList(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                })
            },
            submitRules() {
                console.log(this.addForm)
                let isErrNum = 0;
                if (this.addForm.sign) {
                    this.$refs.sign.$el.classList.remove('isError');
                } else {
                    this.$refs.sign.$el.classList.add('isError');
                    isErrNum = 1;
                }
                if (this.addForm.express) {
                    this.$refs.express.$el.classList.remove('isError');
                } else {
                    this.$refs.express.$el.classList.add('isError');
                    isErrNum = 1;
                }
                if (this.addForm.shopping) {
                    this.$refs.shopping.$el.classList.remove('isError');
                } else {
                    this.$refs.shopping.$el.classList.add('isError');
                    isErrNum = 1;
                }
                if (this.addForm.sending) {
                    this.$refs.sending.$el.classList.remove('isError');
                } else {
                    this.$refs.sending.$el.classList.add('isError');
                    isErrNum = 1;
                }
                if (this.addForm.pick) {
                    this.$refs.pick.$el.classList.remove('isError');
                } else {
                    this.$refs.pick.$el.classList.add('isError');
                    isErrNum = 1;
                }
                if (this.addForm.dynamic) {
                    this.$refs.dynamic.$el.classList.remove('isError');
                } else {
                    this.$refs.dynamic.$el.classList.add('isError');
                    isErrNum = 1;
                }
                if (isErrNum == 0) {
                    let params = this.addForm
                    points_config_setPointsConfit(params).then(res => {
                        this.getInfo()
                        this.$message.success(res.msg)
                    })
                } else {
                    this.$message.warning('每项为必填！')
                    return
                }
            },
            handleClick() {
                switch (Number(this.value)) {
                    case 0:
                        this.getInfo()
                        break;
                    case 1:
                        this.listPages.currentPageNum = 1
                        this.getList()
                        break;
                }
            },
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;

        & > .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
            }
        }
    }

    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .form-content {
        flex: 1;
        height: 1%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .el-input {
        width: 300px;
    }

    .footer-box {
        justify-content: start;
        display: flex;
    }

    .form-item {
        display: flex;
        height: 38px;
        margin-top: 20px;
        width: 450px;

        &:first-child {
            margin-top: 0;
        }

        .item-title {
            width: 150px;
            background: #F6F6F6;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #eee;
        }

        .item-right {
            height: 100%;
            flex: 1;
        }

        .item-value {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #eee;
        }
    }

    .number-input {
        ::v-deep .el-input__inner {
            text-align: left;
            border-radius: 0;
        }
    }

</style>